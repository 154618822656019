import React from 'react';
import DataBlock from './data_block.js.jsx';

export default class EditPropertyUnitsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buildingName: 'Building',
      unitCount: 0,
      formFields: props.formFields,
      customFields: props.customFields,
      amenities: props.amenities,
      batchEdit: false,
      changesDetected: false,
      hideBedBath: props.hideBedBath,
      bedBathShow: props.bedBathShow,
    };
    this.editInstance = 0;
    this.selectedUnits = [];
    this.handleUnitSelect = this.handleUnitSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleRemoveAll = this.handleRemoveAll.bind(this);
    this.handleAmenityAllButton = this.handleAmenityAllButton.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCustomFieldChange = this.handleCustomFieldChange.bind(this);
    this.updateChangesDetected = this.updateChangesDetected.bind(this);
    this.applyChanges = this.applyChanges.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.nextButtonOnClick = this.nextButtonOnClick.bind(this);
  }

  componentDidMount() {
    window.mdc.autoInit($('#new-property-lightbox-page3')[0]);
    initReactSelect2(
      '#new-property-lightbox-page3 .pm-default-select2-menu',
      this.handleChange,
      '100%',
      true
    );

    $('#unit-description')
      .each(function () {
        this.setAttribute(
          'style',
          'height:' +
            remAdjustedValue(this.scrollHeight) +
            ';overflow-y:hidden;'
        );
      })
      .on('input', function () {
        this.style.height = 'auto';
        this.style.height = remAdjustedValue(this.scrollHeight);
      });
  }

  componentDidUpdate() {
    window.mdc.autoInit($('#new-property-lightbox-page3')[0]);
    initReactSelect2(
      '#new-property-lightbox-page3 .pm-default-select2-menu',
      this.handleChange,
      '100%',
      true
    );
  }

  updateDetails(buildingName, unitCount, hiddenAttributes) {
    var panel = this;
    var hiddenFieldKeys = Object.keys(hiddenAttributes);
    panel.formData = {};
    panel.selectedUnits = [];
    for (var x = 0; x < unitCount; x++) {
      panel.selectedUnits.push(x);
      panel.formData[x] = JSON.parse(JSON.stringify(this.state.formFields));
      if (unitCount > 1) panel.formData[x]['number'] = x + 1;
      hiddenFieldKeys.forEach(function (key) {
        panel.formData[x][key] = hiddenAttributes[key];
      });
      for (var y = 0; y < panel.state.customFields.length; y++) {
        var typeId = panel.state.customFields[y].typeId;
        panel.formData[x]['custom_fields_attributes'][typeId] = {};
        panel.formData[x]['custom_fields_attributes'][typeId][
          'custom_field_type_id'
        ] = typeId;
        panel.formData[x]['custom_fields_attributes'][typeId]['value'] =
          panel.state.customFields[y].value;
      }
    }
    this.editInstance += 1;
    this.setState({
      buildingName: buildingName,
      unitCount: unitCount,
      batchEdit: panel.selectedUnits.length > 1,
      changesDetected: false,
    });
  }

  handleUnitSelect(event) {
    let unitIdx = $(event.target).data('unit-index');
    let x = this.selectedUnits.indexOf(unitIdx);

    if (x > -1) this.selectedUnits.splice(x, 1);
    else this.selectedUnits.push(unitIdx);

    if (this.selectedUnits.length == 1) {
      this.state.formFields.number =
        this.formData[this.selectedUnits[0]].number;
      this.setState({
        formFields: JSON.parse(
          JSON.stringify(this.formData[this.selectedUnits[0]])
        ),
      });
    } else this.state.formFields.number = '';

    this.setState({
      changesDetected: this.selectedUnits.length > 0,
      batchEdit: this.selectedUnits.length > 1,
    });
  }

  marketingTextHelper(bedrooms, bathrooms, propertyTypeId, bedBathShow) {
    let propertyTypeName = $(
      'select[name="property_type_id"]',
      '#new-property-lightbox-page3'
    ).val();
    let beds = parseInt(bedrooms);
    let baths = parseInt(bathrooms);

    var marketingString;
    if (bedBathShow) {
      marketingString = beds + ' bedroom';
      if (beds > 1) marketingString += 's';
      marketingString += ', ' + baths + ' bathroom';
      if (baths > 1) marketingString += 's';
    } else
      marketingString = $("input[name='squarefootage']").val() + ' square foot';
    marketingString += ' ' + propertyTypeName + ' for lease';

    return marketingString;
  }

  handleChange(event) {
    let target = event.currentTarget;
    let selector = '.pm-flex:eq(0)';
    if (target.tagName === 'SELECT') {
      selector = '.pm-flex:eq(1)';
    }
    if (
      this.state.batchEdit &&
      !$(target)
        .parents(selector)
        .find('input[type="checkbox"]')
        .prop('checked')
    ) {
      $(target)
        .parents(selector)
        .find('input[type="checkbox"]')
        .prop('checked', true);
    }
    let expectedMarketingText = null;
    let name = $(target).attr('name');
    let value = $(target).is('select')
      ? $(target).find('option:selected').data('value')
      : $(target).val();
    let formFields = JSON.parse(JSON.stringify(this.state.formFields));
    var bedBathShow = true;

    if (
      this.state.hideBedBath.indexOf(
        $("select[name='property_type_id']").val()
      ) > -1
    ) {
      bedBathShow = false;
      formFields['bedrooms'] = 1;
      formFields['bathrooms'] = 1;
    }

    if (['bedrooms', 'bathrooms', 'property_type_id'].indexOf(name) > -1) {
      expectedMarketingText = this.marketingTextHelper(
        formFields['bedrooms'],
        formFields['bathrooms'],
        formFields['property_type_id'],
        bedBathShow
      );
    }
    formFields[name] = value;

    /* Update Marketing Text (if applicable) */
    if (formFields['description'] != expectedMarketingText) {
      formFields['description'] = this.marketingTextHelper(
        formFields['bedrooms'],
        formFields['bathrooms'],
        formFields['property_type_id'],
        bedBathShow
      );
    }

    if ($(event.target).attr('id') == 'unit-description')
      formFields['description'] = $(event.target).val();

    this.setState({
      changesDetected: true,
      formFields: formFields,
      bedBathShow: bedBathShow,
    });
  }

  handleCustomFieldChange(event) {
    let target = event.currentTarget;
    let selector = '.pm-flex:eq(2)';
    if (
      this.state.batchEdit &&
      !$(event.currentTarget)
        .parents(selector)
        .find('input[type="checkbox"]')
        .prop('checked')
    ) {
      $(event.currentTarget)
        .parents(selector)
        .find('input[type="checkbox"]')
        .prop('checked', true);
    }
    let contents = handleDatumBlockChange(
      this.state.customFields.slice(),
      target
    );
    this.setState({ customFields: contents, changesDetected: true });
  }

  applyChanges() {
    var panel = this;
    panel.selectedUnits.forEach(function (unitIdx) {
      console.log('Format Changes for Unit ' + unitIdx);
      /* Apply Applicable Form Changes */
      Object.keys(panel.state.formFields).forEach(function (fieldName) {
        if (
          fieldName.split('_attributes').length == 1 &&
          (fieldName != 'number' || !panel.state.batchEdit)
        ) {
          console.log(fieldName + ' : ' + panel.state.formFields[fieldName]);
          panel.formData[unitIdx][fieldName] =
            panel.state.formFields[fieldName];
        }
      });

      /* Apply Applicable Custom Field Changes */
      panel.state.customFields.forEach(function (customField) {
        var typeId = customField.typeId;
        console.log('customFieldType: ' + typeId);
        console.log('==>' + customField.value);
        panel.formData[unitIdx]['custom_fields_attributes'][typeId]['value'] =
          customField.value;
      });

      /* Add Selected Amenities */
      panel.formData[unitIdx]['amenity_relationships_attributes'] = {};
      $.each(
        $(
          '#unit-amenity-list input[type="checkbox"]:checked',
          '#new-property-lightbox-page3'
        ),
        function () {
          var amenityId = $(this).data('value');
          console.log('amenityId: ' + amenityId);
          panel.formData[unitIdx]['amenity_relationships_attributes'][
            amenityId
          ] = { amenity_id: amenityId };
        }
      );
      console.log(panel.formData[unitIdx]);
    });
    panel.setState({ changesDetected: false });
  }

  handleSubmit() {
    startLoadingSpinner('new-property-lightbox-page4');
    var unitsAttributes = this.formData;
    var formElement = $('#mdc-dialog-newproperty1 form')[0];
    var formData = new FormData(formElement);
    $('#save-new-property').prop('disabled', true);
    const addressAttributes = {};
    formData.forEach(function (val, key) {
      if (key.includes('address_attributes')) {
        addressAttributes[key] = val;
      }
    });
    Object.values(unitsAttributes).forEach(function (obj) {
      Object.keys(addressAttributes).forEach(function (val) {
        obj[val.replace('building[address_attributes]', 'address_attributes')] =
          addressAttributes[val];
      });
    });
    Object.keys(unitsAttributes).forEach(function (key) {
      var unitAttrs = unitsAttributes[key];
      var unitEncoded = encodeURIComponent($.param(unitAttrs));
      decodeURIComponent(unitEncoded.replace(/%2B/g, ' '))
        .split('&')
        .forEach(function (field) {
          formData.append(
            'building[units_attributes][' +
              key +
              ']' +
              decodeURIComponent(field.split('=')[0]),
            decodeURIComponent(field.split('=')[1])
          );
        });
    });

    var editserialize = decodeURIComponent(
      $.param(unitsAttributes).replace(/%2F/g, ' ')
    );
    formData.append('authenticity_token', AUTH_TOKEN);
    $.ajax({
      url: '/buildings/create_redesign',
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      success: function (data) {
        // Check for permissions alert.
        if (typeof data === 'string') {
          eval(data);
        } else {
          if (data.errors == null) {
            $(
              '#new-property-lightbox-page4 .close-new-property-lightbox'
            ).click();
            if (data.automatic_action_errors != null) {
              alert(data.automatic_action_errors);
            }
          } else {
            alert(data.errors);
          }
        }
        stopLoadingSpinner('new-property-lightbox-page4');
        $('#save-new-property').prop('disabled', false);
      },
      error: function () {
        alert('An error occured while processing your request.');
      },
    });
  }

  handleSelectAll(event) {
    var that = this;
    that.selectedUnits = [];
    $.each(
      $(event.target)
        .parents('.pm-checkbox-list--wrap')
        .first()
        .find('input[type="checkbox"]'),
      function () {
        $(this).prop('checked', true);
        that.selectedUnits.push($(this).data('unit-index'));
      }
    );
    if (this.selectedUnits.length == 1)
      this.state.formFields.number =
        this.formData[this.selectedUnits[0]].number;
    this.setState({
      changesDetected: true,
      batchEdit: this.selectedUnits.length > 1,
    });
  }

  handleRemoveAll(event) {
    this.selectedUnits = [];
    $.each(
      $(event.target)
        .parents('.pm-checkbox-list--wrap')
        .first()
        .find('input[type="checkbox"]'),
      function () {
        $(this).prop('checked', false);
      }
    );
    this.state.formFields.number = '';
    this.setState({ changesDetected: false, batchEdit: false });
  }

  handleAmenityAllButton(event) {
    let checked = $(event.target).attr('id') == 'amenity-select-all-button';
    var changesDetected = false;
    $.each(
      $(event.target)
        .parents('.pm-checkbox-list--wrap')
        .first()
        .find('input[type="checkbox"]'),
      function () {
        if (!changesDetected)
          changesDetected = $(this).prop('checked') != checked;
        $(this).prop('checked', checked);
      }
    );
    this.setState({ changesDetected: changesDetected });
  }

  updateChangesDetected() {
    if (!this.state.changesDetected) this.setState({ changesDetected: true });
  }

  handleBackButton() {
    $('#new-property-lightbox-page2').show();
    $('#new-property-lightbox-page3-container').hide();
  }

  nextButtonOnClick() {
    const propertyAmenitiesPage = $('#new-property-lightbox-page4');
    const propertyTypeIds = Object.values(this.formData).map(
      (el) => el['property_type_id']
    );
    let anyAmenities = false;
    this.props.amenities.forEach(function (amenity) {
      const shared = propertyTypeIds.every((val) =>
        amenity.propertyTypeIds.includes(val)
      );
      anyAmenities = anyAmenities || shared;
      if (!shared) {
        propertyAmenitiesPage
          .find(`div[data-amenity-id = ${amenity.id}] input[type = 'checkbox']`)
          .prop('checked', false);
        propertyAmenitiesPage
          .find(`div[data-amenity-id = ${amenity.id}] input[type = 'checkbox']`)
          .prop('disabled', true);
        propertyAmenitiesPage
          .find(`#hidden-amenity-field-${amenity.id}`)
          .prop('disabled', true);
        propertyAmenitiesPage
          .find(`div[data-amenity-id = ${amenity.id}]`)
          .closest('.pm-flex')
          .hide();
      } else {
        propertyAmenitiesPage
          .find(`div[data-amenity-id = ${amenity.id}] input[type = 'checkbox']`)
          .prop('disabled', false);
        propertyAmenitiesPage
          .find(`div[data-amenity-id = ${amenity.id}]`)
          .closest('.pm-flex')
          .show();
      }
    });
    if (anyAmenities) {
      $('#amenity-scrollable-section').show();
      $('#no-amenity-message').hide();
    } else {
      $('#amenity-scrollable-section').hide();
      $('#no-amenity-message').show();
    }

    propertyAmenitiesPage.show();
    $('#new-property-lightbox-page3-container').hide();
  }

  render() {
    const that = this;
    var unitList = [];
    for (var idx = 0; idx < parseInt(this.state.unitCount); idx++) {
      unitList.push(
        <div
          key={'unitSelect' + idx + that.editInstance}
          className="pm-checkbox-list--row"
        >
          <div className="mdc-form-field">
            <div
              id="mdc-js-checkbox"
              className="mdc-checkbox"
              data-mdc-auto-init="MDCCheckbox"
            >
              <input
                data-unit-index={idx}
                data-checked={true}
                defaultChecked
                type="checkbox"
                id={'pm-checkbox-list-item-' + idx}
                className="mdc-checkbox__native-control"
                onClick={this.handleUnitSelect}
              />
              <div className="mdc-checkbox__background">
                <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                  <path
                    className="mdc-checkbox__checkmark-path"
                    fill="none"
                    stroke="white"
                    d="M1.73,12.91 8.1,19.28 22.79,4.59"
                  />
                </svg>
                <div className="mdc-checkbox__mixedmark"></div>
              </div>
            </div>
            <label
              htmlFor={'pm-checkbox-list-item-' + idx}
              id="my-checkbox-label"
            >
              Unit {idx + 1}
            </label>
          </div>
        </div>
      );
    }

    var propertyTypeList;
    var propertyType;
    if (typeof this.props.propertyTypeOptions !== 'undefined') {
      propertyTypeList = this.props.propertyTypeOptions.map(function (option) {
        if (option[1] == that.state.formFields.property_type_id)
          propertyType = option[0];
        return (
          <option key={'propType' + option[1]} data-value={option[1]}>
            {option[0]}
          </option>
        );
      });
    }

    var amenityList;
    if (typeof this.props.amenities !== 'undefined') {
      let propertyTypeId = parseInt(
        $("select[name='property_type_id'] option:selected").attr('data-value')
      );
      if (
        !propertyTypeId &&
        Array.isArray(this.props.propertyTypeOptions) &&
        this.props.propertyTypeOptions.length > 0
      ) {
        propertyTypeId = this.props.propertyTypeOptions[0][1];
      }
      let tmpAmenities = deepCopyArray(this.props.amenities);
      if (propertyTypeId) {
        tmpAmenities = tmpAmenities.filter((amenity) => {
          return amenity.propertyTypeIds.includes(propertyTypeId);
        });
      }
      amenityList = tmpAmenities.map(function (amenity) {
        let amenityIdentifier = `unitAmenity${amenity.id}${that.editInstance}`;
        return (
          <div key={amenityIdentifier} className="pm-checkbox-list--row">
            <div className="mdc-form-field">
              <div
                id="mdc-js-checkbox"
                className="mdc-checkbox"
                data-mdc-auto-init="MDCCheckbox"
              >
                <input
                  name="amenity_relationships_attributes"
                  data-name={amenity.name}
                  data-value={amenity.id}
                  type="checkbox"
                  id={amenityIdentifier}
                  className="mdc-checkbox__native-control"
                  onClick={that.updateChangesDetected}
                />
                <div className="mdc-checkbox__background">
                  <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                    {' '}
                    <path
                      className="mdc-checkbox__checkmark-path"
                      fill="none"
                      stroke="white"
                      d="M1.73,12.91 8.1,19.28 22.79,4.59"
                    />
                  </svg>
                  <div className="mdc-checkbox__mixedmark"></div>
                </div>
              </div>
              <label htmlFor={amenityIdentifier} id="my-checkbox-label">
                {amenity.name}
              </label>
            </div>
          </div>
        );
      });
    }

    var customFieldList = [];
    if (typeof this.props.customFields !== 'undefined') {
      var objectIndex = 0;
      that.state.customFields.forEach(function (object) {
        objectIndex += 1;
        let customFieldStyle = {};
        if (object.hidden) {
          customFieldStyle['display'] = 'none';
        }
        customFieldList.push(
          <div
            key={'customField' + objectIndex + that.state.editInstance}
            className={`pm-flex pm-flex-col pm-margin-bottom-20 pm-check-input-lockup${
              object.required ? ' required_field' : ''
            }`}
            style={customFieldStyle}
          >
            <div className="pm-flex">
              {that.state.batchEdit && (
                <div
                  id="mdc-js-checkbox"
                  className="mdc-checkbox"
                  data-mdc-auto-init="MDCCheckbox"
                >
                  <input
                    type="checkbox"
                    id={'custom-field-checkbox-' + object.typeId}
                    className="mdc-checkbox__native-control"
                  />
                  <div className="mdc-checkbox__background">
                    <svg
                      className="mdc-checkbox__checkmark"
                      viewBox="0 0 24 24"
                    >
                      {' '}
                      <path
                        className="mdc-checkbox__checkmark-path"
                        fill="none"
                        stroke="white"
                        d="M1.73,12.91 8.1,19.28 22.79,4.59"
                      />
                    </svg>
                    <div className="mdc-checkbox__mixedmark"></div>
                  </div>
                </div>
              )}
              <DataBlock
                key={'dataBlock' + objectIndex}
                type={object.type}
                title={object.title}
                displayValue={object.displayValue}
                value={object.value}
                fieldName={object.fieldName}
                menuItems={object.menuItems}
                handleChange={that.handleCustomFieldChange}
                editOnly={true}
                nativeInput={'true'}
              />
            </div>
          </div>
        );
      });
    }
    return (
      <div id="new-property-lightbox-page3">
        <header className="mdc-dialog__header pm-bg-color--blue1">
          <h2
            id="mdc-dialog-default-label"
            className="mdc-dialog__header__title"
          >
            Edit Units
          </h2>
          <span className="pm-modalstep">Step 3/4</span>
        </header>
        <section
          id="mdc-dialog-default-description"
          className="mdc-dialog__body mdc-dialog__body--scrollable"
        >
          <p className="pm-margin-top-none">
            You can batch edit or individually edit unit details.
          </p>

          <div className="pm-batchedit-unitwrap pm-flex pm-gutter-12">
            <div
              className="pm-batchedit--unitselect pm-flex pm-flex-col"
              style={{ marginRight: '1.875rem' }}
            >
              <h3>Select Unit(s)</h3>
              <div className="pm-checkbox-list--wrap">
                <div
                  className="pm-checkbox-list--inner pm-checkbox-list--high"
                  style={{
                    maxHeight: '93.75rem',
                    overflow:
                      this.selectedUnits.length > 35 ? 'auto' : 'hidden',
                    marginBottom: '0.625rem',
                  }}
                >
                  <p className="pm-text-size-12 pm-text-color--grey4 pm-margin-top-none">
                    {this.state.buildingName}
                  </p>

                  {unitList}
                </div>
                <div className="pm-checkbox-list--footer">
                  <button
                    type="button"
                    className="mdc-button pm-text-color--blue1 mdc-button--dense mdc-button--compact select-all-units"
                    data-mdc-auto-init="MDCRipple"
                    onClick={this.handleSelectAll}
                  >
                    Select All
                  </button>
                  <button
                    type="button"
                    className="mdc-button pm-text-color--grey5 mdc-button--dense mdc-button--compact remove-all-units"
                    data-mdc-auto-init="MDCRipple"
                    style={{ display: 'block' }}
                    onClick={this.handleRemoveAll}
                  >
                    Remove All
                  </button>
                </div>
              </div>
            </div>
            {this.selectedUnits.length < 1 && (
              <div className="pm-batchedit--unitedit pm-flex pm-flex-col">
                <h3>Edit Unit(s) details</h3>
                <p className="pm-inline-warning pm-text-color--grey7 pm-margin-top-none">
                  Select a unit on the left to edit its details, or select
                  multiple units to batch edit.
                </p>
              </div>
            )}

            {this.selectedUnits.length > 0 && (
              <div className="pm-batchedit--unitedit pm-flex pm-flex-col">
                <div id="unit-edit-container">
                  <h3>Edit Unit(s) details</h3>
                  {this.state.batchEdit && (
                    <p className="pm-inline-warning pm-text-color--red1 pm-margin-top-none">
                      You are batch editing multiple units. This will override
                      any individual values that were previously set.
                    </p>
                  )}
                  <div
                    className="pm-flex pm-flex-2"
                    style={{ display: this.state.batchEdit ? 'none' : 'flex' }}
                  >
                    <div className="pm-flex pm-flex-col pm-margin-bottom-20 pm-check-input-lockup">
                      <div className="pm-flex">
                        <div
                          className="mdc-text-field pm-margin-none"
                          data-mdc-auto-init="MDCTextField"
                        >
                          <input
                            type="text"
                            className="mdc-text-field__input"
                            id="units_number_field"
                            name="number"
                            value={this.state.formFields.number}
                            onChange={this.handleChange}
                          />
                          <label
                            htmlFor="units_number_field"
                            className="mdc-floating-label"
                          >
                            Unit Number
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="pm-flex pm-flex-col pm-margin-bottom-20 pm-check-input-lockup">
                      <div className="pm-flex">
                        {this.state.batchEdit && (
                          <div
                            id="mdc-js-checkbox"
                            className="mdc-checkbox"
                            data-mdc-auto-init="MDCCheckbox"
                          >
                            <input
                              type="checkbox"
                              id="native-js-checkbox2"
                              className="mdc-checkbox__native-control"
                            />
                            <div className="mdc-checkbox__background">
                              <svg
                                className="mdc-checkbox__checkmark"
                                viewBox="0 0 24 24"
                              >
                                {' '}
                                <path
                                  className="mdc-checkbox__checkmark-path"
                                  fill="none"
                                  stroke="white"
                                  d="M1.73,12.91 8.1,19.28 22.79,4.59"
                                />
                              </svg>
                              <div className="mdc-checkbox__mixedmark"></div>
                            </div>
                          </div>
                        )}
                        <div
                          className="mdc-text-field pm-margin-none"
                          data-mdc-auto-init="MDCTextField"
                        >
                          <input
                            type="text"
                            className="mdc-text-field__input"
                            id="units_default_rent"
                            name="default_rent"
                            onChange={this.handleChange}
                            value={this.state.formFields.default_rent}
                          />
                          <label
                            htmlFor="units_default_rent"
                            className="mdc-floating-label"
                          >
                            Target rent (per month)
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.bedBathShow && (
                    <div className="pm-flex pm-flex-2">
                      <div className="pm-flex pm-flex-col pm-margin-bottom-20 pm-check-input-lockup">
                        <div className="pm-flex">
                          {this.state.batchEdit && (
                            <div
                              id="mdc-js-checkbox"
                              className="mdc-checkbox"
                              data-mdc-auto-init="MDCCheckbox"
                            >
                              <input
                                type="checkbox"
                                id="native-js-checkbox2"
                                className="mdc-checkbox__native-control"
                              />
                              <div className="mdc-checkbox__background">
                                <svg
                                  className="mdc-checkbox__checkmark"
                                  viewBox="0 0 24 24"
                                >
                                  {' '}
                                  <path
                                    className="mdc-checkbox__checkmark-path"
                                    fill="none"
                                    stroke="white"
                                    d="M1.73,12.91 8.1,19.28 22.79,4.59"
                                  />
                                </svg>
                                <div className="mdc-checkbox__mixedmark"></div>
                              </div>
                            </div>
                          )}

                          <div
                            className="mdc-text-field pm-margin-none"
                            data-mdc-auto-init="MDCTextField"
                          >
                            <input
                              type="text"
                              className="mdc-text-field__input"
                              id="units_bedrooms_field"
                              name="bedrooms"
                              value={this.state.formFields.bedrooms}
                              onChange={this.handleChange}
                            />
                            <label
                              htmlFor="units_bedrroms_field"
                              className="mdc-floating-label"
                            >
                              Bedrooms
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="pm-flex pm-flex-col pm-margin-bottom-20 pm-check-input-lockup">
                        <div className="pm-flex">
                          {this.state.batchEdit && (
                            <div
                              id="mdc-js-checkbox"
                              className="mdc-checkbox"
                              data-mdc-auto-init="MDCCheckbox"
                            >
                              <input
                                type="checkbox"
                                id="native-js-checkbox2"
                                className="mdc-checkbox__native-control"
                              />
                              <div className="mdc-checkbox__background">
                                <svg
                                  className="mdc-checkbox__checkmark"
                                  viewBox="0 0 24 24"
                                >
                                  {' '}
                                  <path
                                    className="mdc-checkbox__checkmark-path"
                                    fill="none"
                                    stroke="white"
                                    d="M1.73,12.91 8.1,19.28 22.79,4.59"
                                  />
                                </svg>
                                <div className="mdc-checkbox__mixedmark"></div>
                              </div>
                            </div>
                          )}
                          <div
                            className="mdc-text-field pm-margin-none"
                            data-mdc-auto-init="MDCTextField"
                          >
                            <input
                              type="text"
                              className="mdc-text-field__input"
                              id="units_bathrooms_field"
                              name="bathrooms"
                              value={this.state.formFields.bathrooms}
                              onChange={this.handleChange}
                            />
                            <label
                              htmlFor="units_bathrooms_field"
                              className="mdc-floating-label"
                            >
                              Bathrooms
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="pm-flex pm-flex-2">
                    <div className="pm-flex pm-flex-col pm-margin-bottom-20 pm-check-input-lockup">
                      {this.state.batchEdit && (
                        <div
                          id="mdc-js-checkbox"
                          className="mdc-checkbox"
                          data-mdc-auto-init="MDCCheckbox"
                        >
                          <input
                            type="checkbox"
                            id="native-js-checkbox2"
                            className="mdc-checkbox__native-control"
                          />
                          <div className="mdc-checkbox__background">
                            <svg
                              className="mdc-checkbox__checkmark"
                              viewBox="0 0 24 24"
                            >
                              {' '}
                              <path
                                className="mdc-checkbox__checkmark-path"
                                fill="none"
                                stroke="white"
                                d="M1.73,12.91 8.1,19.28 22.79,4.59"
                              />
                            </svg>
                            <div className="mdc-checkbox__mixedmark"></div>
                          </div>
                        </div>
                      )}
                      <div className="pm-flex">
                        <div className="pm-select" role="listbox" tabIndex="0">
                          <label>Unit type</label>
                          <select
                            onChange={this.handleChange}
                            value={propertyType}
                            name="property_type_id"
                            id="property_type_select"
                            className="pm-default-select2-menu mdc-text-field__input mdc-list mdc-menu__items"
                          >
                            {propertyTypeList}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="pm-flex pm-flex-col pm-margin-bottom-20 pm-check-input-lockup">
                      <div className="pm-flex">
                        {this.state.batchEdit && (
                          <div
                            id="mdc-js-checkbox"
                            className="mdc-checkbox"
                            data-mdc-auto-init="MDCCheckbox"
                          >
                            <input
                              type="checkbox"
                              id="native-js-checkbox2"
                              className="mdc-checkbox__native-control"
                            />
                            <div className="mdc-checkbox__background">
                              <svg
                                className="mdc-checkbox__checkmark"
                                viewBox="0 0 24 24"
                              >
                                {' '}
                                <path
                                  className="mdc-checkbox__checkmark-path"
                                  fill="none"
                                  stroke="white"
                                  d="M1.73,12.91 8.1,19.28 22.79,4.59"
                                />
                              </svg>
                              <div className="mdc-checkbox__mixedmark"></div>
                            </div>
                          </div>
                        )}
                        <div
                          className="mdc-text-field pm-margin-none"
                          data-mdc-auto-init="MDCTextField"
                        >
                          <input
                            type="text"
                            className="mdc-text-field__input"
                            id="units_squarefootage"
                            name="squarefootage"
                            onChange={this.handleChange}
                            value={this.state.formFields.squarefootage}
                          />
                          <label
                            htmlFor="units_squarefootage"
                            className="mdc-floating-label"
                          >
                            {window.areaUnitFull}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pm-flex pm-flex-2">
                    <div className="pm-flex pm-flex-col pm-margin-bottom-20 pm-check-input-lockup">
                      <div className="pm-flex">
                        {this.state.batchEdit && (
                          <div
                            id="mdc-js-checkbox"
                            className="mdc-checkbox"
                            data-mdc-auto-init="MDCCheckbox"
                          >
                            <input
                              type="checkbox"
                              id="native-js-checkbox2"
                              className="mdc-checkbox__native-control"
                            />
                            <div className="mdc-checkbox__background">
                              <svg
                                className="mdc-checkbox__checkmark"
                                viewBox="0 0 24 24"
                              >
                                {' '}
                                <path
                                  className="mdc-checkbox__checkmark-path"
                                  fill="none"
                                  stroke="white"
                                  d="M1.73,12.91 8.1,19.28 22.79,4.59"
                                />
                              </svg>
                              <div className="mdc-checkbox__mixedmark"></div>
                            </div>
                          </div>
                        )}
                        <div
                          className="mdc-text-field mdc-text-field--multiline mdc-text-field--upgraded pm-margin-none"
                          data-mdc-auto-init="MDCTextField"
                        >
                          <textarea
                            name="description"
                            id="unit-description"
                            className="mdc-text-field__input"
                            style={{ height: '4.25rem', paddingTop: '0.5rem' }}
                            rows="1"
                            cols="30"
                            placeholder="Marketing Text..."
                            onChange={this.handleChange}
                            value={this.state.formFields.description}
                          ></textarea>
                          <label
                            htmlFor="unit-description"
                            className="mdc-floating-label"
                          >
                            Description
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pm-flex pm-flex-2">{customFieldList}</div>

                  <h3>Unit amenities</h3>
                  <div className="pm-checkbox-list--wrap">
                    <div
                      id="unit-amenity-list"
                      className="pm-checkbox-list--inner pm-checkbox-list--2col pm-checkbox-list--low"
                    >
                      {amenityList}
                    </div>
                    <div className="pm-checkbox-list--footer">
                      <button
                        type="button"
                        id="amenity-remove-all-button"
                        className="mdc-button pm-text-color--grey5 mdc-button--dense mdc-button--compact"
                        data-mdc-auto-init="MDCRipple"
                        onClick={this.handleAmenityAllButton}
                      >
                        Remove All
                      </button>
                      <button
                        type="button"
                        id="amenity-select-all-button"
                        className="mdc-button pm-text-color--blue1 mdc-button--dense mdc-button--compact"
                        data-mdc-auto-init="MDCRipple"
                        onClick={this.handleAmenityAllButton}
                      >
                        Select All
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {this.state.changesDetected && this.selectedUnits.length > 0 && (
            <div className="pm-button-group pm-right-align">
              <button
                id="apply-changes-button"
                type="button"
                className="mdc-button mdc-button--raised pm-bg-color--blue1 pm-text-color--white"
                data-mdc-auto-init="MDCRipple"
                onClick={this.applyChanges}
              >
                Apply Changes
              </button>
            </div>
          )}
        </section>

        <footer className="mdc-dialog__footer">
          <button
            type="button"
            className="mdc-button pm-text-color--grey5 mdc-dialog__footer__button back-button"
            onClick={this.handleBackButton}
          >
            Back
          </button>
          <button
            type="button"
            className="mdc-button pm-text-color--grey5 mdc-dialog__footer__button close-new-property-lightbox"
          >
            Cancel
          </button>
          <button
            type="button"
            disabled={this.state.changesDetected}
            className="mdc-button pm-text-color--blue1 mdc-dialog__footer__button"
            id="next-page-btn3"
            onClick={this.nextButtonOnClick}
          >
            Next
          </button>
        </footer>
      </div>
    );
  }
}
